import { defineAsyncComponent } from 'vue'
import {SetupCalendar} from 'v-calendar'
import GiftCardBuilder from './components3/GiftCardBuilder'

export default {
  install(app) {
    app.use(SetupCalendar)
    app.component('ticket-shop', defineAsyncComponent(() => (import('./components3/TicketShop.vue'))))
    app.component('loading-overlay', defineAsyncComponent(() => (import('./components3/LoadingOverlay.vue'))))
    app.component('ticketing-layout', defineAsyncComponent(() => (import('./components3/Layout.vue'))))
    app.component('ticketing-date-picker', defineAsyncComponent(() => (import('./components3/DatePicker.vue'))))
    app.component('ticketing-slot-picker', defineAsyncComponent(() => (import('./components3/SlotSelector.vue'))))
    app.component('ticketing-category-list', defineAsyncComponent(() => (import('./components3/CategoryList.vue'))))
    app.component('ticketing-product-list', defineAsyncComponent(() => (import('./components3/ProductList.vue'))))
    app.component('ticketing-summary', defineAsyncComponent(() => (import('./components3/Summary.vue'))))
    app.component('ticketing-submit', defineAsyncComponent(() => (import('./components3/SubmitButton.vue'))))
    app.component('ticketing-debugger', defineAsyncComponent(() => (import('./components3/DebugComponent.js'))))
    app.component('ticketing-birthday-picker', defineAsyncComponent(() => (import('../ticket-admin/components/BirthDateSelector.vue'))))
    app.component('ticketing-avatar-upload', defineAsyncComponent(() => (import('./components3/AvatarUpload.vue'))))
    app.component('ticketing-info-blocks', defineAsyncComponent(() => (import('./components3/InfoBlocks.vue'))))
    app.use(GiftCardBuilder)
  }
}
