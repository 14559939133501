<template>
  <svg viewBox="0 0 400 124" preserveAspectRatio="xMinYMid meet" xmlns="http://www.w3.org/2000/svg">
    <path d="m69.338 30.349 38.117-21.784-27.225 6.806V3.124L42.074 24.908l27.225-6.816.04 12.257Zm76.234-10.892a16.324 16.324 0 0 0-6.079-12.838 16.334 16.334 0 0 0-24.963 19.814L96.025 44.968a43.389 43.389 0 0 0-53.373 0l-7.614-7.614a16.304 16.304 0 1 0-14.708 9.328 16.192 16.192 0 0 0 6.975-1.634l7.624 7.623a43.567 43.567 0 1 0 68.759 0l18.516-18.515a16.24 16.24 0 0 0 21.336-6.8 16.235 16.235 0 0 0 2.002-7.899" fill="#1D1D1B" class="logo-symbol-dark"/>
    <path d="M25.77 30.349a5.441 5.441 0 1 0-5.44 5.44 5.431 5.431 0 0 0 5.44-5.44Zm76.244 49.009a32.673 32.673 0 0 0-39.05-32.048 32.676 32.676 0 1 0 39.05 32.048Zm32.666-59.901a5.447 5.447 0 0 0-3.367-5.037 5.452 5.452 0 1 0-2.074 10.488 5.432 5.432 0 0 0 5.441-5.451" fill="#fff" class="logo-symbol-dark"/>
    <path d="M88.402 79.358c0-2.273-.405-4.527-1.196-6.657a8.162 8.162 0 1 1-11.25-11.211 19.063 19.063 0 1 0 12.406 17.868" fill="#1D1D1B" class="logo-symbol-dark"/>
    <path d="M69.176 98.158a18.838 18.838 0 0 1-13.004-5.708 18.914 18.914 0 0 1-5.345-13.18c0-4.922 1.918-9.651 5.345-13.179a18.838 18.838 0 0 1 13.004-5.707c2.237.002 4.456.39 6.562 1.145a8.178 8.178 0 0 0-3.853 6.821 8.079 8.079 0 0 0 2.356 5.825 8.046 8.046 0 0 0 5.813 2.36 8.155 8.155 0 0 0 6.807-3.86 19.732 19.732 0 0 1 1.143 6.575 18.9 18.9 0 0 1-5.5 13.368 18.796 18.796 0 0 1-13.328 5.54Zm0-49.684a30.615 30.615 0 0 0-17.015 5.193 30.709 30.709 0 0 0-11.275 13.785 30.78 30.78 0 0 0-1.74 17.737 30.744 30.744 0 0 0 8.382 15.721 30.642 30.642 0 0 0 15.68 8.412 30.6 30.6 0 0 0 17.704-1.727 30.676 30.676 0 0 0 13.767-11.286 30.766 30.766 0 0 0 2.875-28.83 30.74 30.74 0 0 0-6.652-9.993 30.668 30.668 0 0 0-9.967-6.675 30.618 30.618 0 0 0-11.76-2.337" fill="#fff" visibility="hidden" class="logo-symbol-light"/>
    <path d="M20.397 36.224a5.998 5.998 0 0 1-4.06-1.858 6.023 6.023 0 0 1 0-8.303 5.998 5.998 0 0 1 4.06-1.858 5.625 5.625 0 0 1 3.989 1.746 6.105 6.105 0 0 1 1.742 4.27 6.105 6.105 0 0 1-1.742 4.284 5.663 5.663 0 0 1-3.99 1.732v-.013Zm48.78 79.232a36.127 36.127 0 0 1-20.093-6.149 36.24 36.24 0 0 1-13.298-16.298 36.33 36.33 0 0 1-2.02-20.954 36.283 36.283 0 0 1 9.939-18.547 36.163 36.163 0 0 1 18.547-9.889 36.118 36.118 0 0 1 20.903 2.103A36.203 36.203 0 0 1 99.37 59.107a36.319 36.319 0 0 1 6.061 20.157 36.251 36.251 0 0 1-10.639 25.628 36.15 36.15 0 0 1-11.758 7.837 36.082 36.082 0 0 1-13.858 2.727Zm55.668-100.282a5.61 5.61 0 0 1 4.002-1.732 5.996 5.996 0 0 1 4.232 1.762 6.023 6.023 0 0 1 1.759 4.24 5.699 5.699 0 0 1-1.675 4.01 6.077 6.077 0 0 1-4.261 1.747 5.875 5.875 0 0 1-4.024-1.725 5.897 5.897 0 0 1-1.721-4.032c0-1.597.626-3.13 1.742-4.27h-.054Zm-2.178 15.757a12.897 12.897 0 0 0 6.235 1.677 13.242 13.242 0 0 0 7.409-2.194 13.287 13.287 0 0 0 4.931-5.96 13.32 13.32 0 0 0 .778-7.703 13.296 13.296 0 0 0-3.638-6.83 13.236 13.236 0 0 0-14.506-2.85 13.267 13.267 0 0 0-5.943 4.947 13.31 13.31 0 0 0-2.183 7.426 13.033 13.033 0 0 0 1.689 6.234L97.344 45.76c-.45-.382-.94-.75-1.361-1.118a43.346 43.346 0 0 0-26.766-9.255 43.346 43.346 0 0 0-26.765 9.255c-.45.354-.926.71-1.362 1.091l-9.23-9.14a13.665 13.665 0 0 0 1.674-6.384 13.32 13.32 0 0 0-2.332-7.355 13.28 13.28 0 0 0-6.015-4.819 13.248 13.248 0 0 0-14.421 3.058A13.326 13.326 0 0 0 8.055 35.61a13.294 13.294 0 0 0 4.95 5.912 13.254 13.254 0 0 0 7.392 2.164 13.01 13.01 0 0 0 6.29-1.733l9.12 9.127c-.38.45-.734.927-1.088 1.364a43.896 43.896 0 0 0-4.8 46.167 43.79 43.79 0 0 0 16.13 17.935 43.668 43.668 0 0 0 23.161 6.651c8.19 0 16.215-2.305 23.162-6.651a43.793 43.793 0 0 0 16.13-17.935 43.907 43.907 0 0 0 4.202-23.773 43.873 43.873 0 0 0-9.002-22.394c-.368-.478-.735-.955-1.116-1.364l20.081-20.15Z" fill="#fff" visibility="hidden" class="logo-symbol-light"/>
    <path d="M78.638 1 40.123 23.086l27.433-6.875v12.428L106.22 6.566l-27.582 6.862V1Z" fill="#fff" visibility="hidden" class="logo-symbol-light"/>
    <path d="M395.428 69.004v-8.71h-24.913v38.087h25.003v-8.71h-15.217v-6.457h12.955v-8.51h-12.955v-5.7h15.127Zm-36.871 0v-8.71h-33.582v8.71h11.958v29.377h9.825V69.004h11.799Zm-45.431-8.71H303.3v38.087h9.826V60.294Zm-37.35 38.087 16.044-38.087h-10.772l-9.417 22.86-9.417-22.86h-10.743l16.034 38.087h8.271Zm-43.846-8.71V60.295h-9.786v38.087h24.913v-8.71H231.93Zm-24.664-29.377h-9.836v38.087h9.836V60.294Zm-37.359 38.087 16.044-38.087h-10.793l-9.427 22.86-9.417-22.86h-10.742l16.084 38.087h8.251Z" fill="#1D1D1B" class="logo-letters"/>
  </svg>
</template>
