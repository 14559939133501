<script setup>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  headingTag: {
    type: String,
    default: 'h2',
  },
  cards: {
    type: Array,
    default: () => [],
  },
})
</script>

<template>
  <div class="NavigationCards">
    <component
      :is="headingTag"
      class="NavigationCards__heading"
    >{{ props.title }}</component>

    <div class="NavigationCards__container">
      <a
        v-for="card in cards"
        :key="`${card.link}-${card.icon}`"
        :href="card.link"
        class="NavigationCards__card"
      >
        <FontAwesomeIcon
          class="NavigationCards__icon"
          :icon="['vv', card.icon]"
        />
        <span>{{ card.title }}</span>
      </a>
    </div>
  </div>
</template>

