<script setup>
import { ref, computed, defineAsyncComponent } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { FocusTrap } from "focus-trap-vue";
import useOrder from "../../ticketing/composables/order";

const CartItem = defineAsyncComponent(() => import("./CartItem.vue"));

const props = defineProps({
  checkoutUrl: {
    type: String,
    default: "/cart",
  },
  cdnUrl: {
    type: String,
    required: true,
  },
  removeItemUrl: {
    type: String,
    required: true,
  },
  updateQuantityUrl: {
    type: [String, null],
    default: null,
  },
  standalone: {
    type: Boolean,
    default: false,
  },
});

const { cartOpen: cartOpenState, order } = useOrder();

const isLoading = ref(false);
const standalone = ref(props.standalone);

const openCart = () => {
  cartOpenState.value = true;
};

const closeCart = () => {
  cartOpenState.value = false;
};

const focusTrapEnabled = computed({
  get: () => {
    return cartOpenState.value && !standalone.value;
  },
  set: (value) => {
    cartOpenState.value = value;
  },
});

const showCart = computed(() => {
  return cartOpenState.value || standalone.value;
});

const root = document.querySelector("html");
const locale = root.lang.replace("_", "-");

const formatPrice = new Intl.NumberFormat(locale, {
  style: "currency",
  currency: "NOK",
});

const deleteItem = async (id) => {
  try {
    isLoading.value = true;
    const url = props.updateQuantityUrl;
    let { data } = await window.axios.post(url, {
      cart_item_id: id,
      quantity: 0,
    });
    order.value = data;
    if (!order.value.cart?.length) {
      cartOpenState.value = false;
    }
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};

const updateQuantity = async (update) => {
  try {
    isLoading.value = true;
    const url = props.updateQuantityUrl;

    let { data } = await window.axios.post(url, {
      cart_item_id: update.id,
      quantity: update.quantity,
    });
    order.value = data;
    if (!order.value.cart?.length) {
      cartOpenState.value = false;
    }
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <Transition :name="standalone ? null : 'webshop-cart'">
    <FocusTrap v-model:active="focusTrapEnabled" fallback-focus="#webshop-cart">
      <section
        v-show="showCart"
        :class="{
          WebshopCart: true,
          'WebshopCart--standalone': standalone,
        }"
        id="webshop-cart"
      >
        <Transition name="webshop-cart-loading">
          <div
            class="WebshopCart__loadingOverlay"
            aria-live="assertive"
            v-if="isLoading"
          >
            <span class="visually-hidden">
              {{ $t("webshop.cart.loading") }}…
            </span>
            <FontAwesomeIcon :icon="['fas', 'circle-notch']" spin />
          </div>
        </Transition>

        <header v-if="!standalone" class="WebshopCart__header">
          <h2 class="WebshopCart__heading">
            {{ $t("webshop.cart.heading") }}
          </h2>

          <button
            class="WebshopCart__closeButton"
            aria-controls="webshop-cart"
            @click="closeCart"
          >
            {{ $t("webshop.cart.close") }}
            <FontAwesomeIcon
              class="ms-1"
              :icon="['fas', 'xmark']"
              fixed-width
            />
          </button>
        </header>

        <ul class="WebshopCart__body" role="list" v-if="order">
          <TransitionGroup name="webshop-cart-items">
            <CartItem
              v-for="item in order.cart"
              :key="item.key"
              :item="item"
              :formatted-price="formatPrice.format(item.price)"
              :cdn-url="cdnUrl"
              :show-quantity-selector="standalone && item.canAdjustQuantity"
              :compact="!standalone"
              @delete="deleteItem"
              @update-quantity="updateQuantity"
            />
          </TransitionGroup>
        </ul>

        <footer class="WebshopCart__footer">
          <slot name="shipping"></slot>
          <div class="WebshopCart__total">
            <span>
              {{ $t("webshop.cart.total") }}
            </span>
            <span class="WebshopCart__totalSum">
              {{ formatPrice.format(order.order_total) }}
            </span>
          </div>

          <a
            v-if="!standalone"
            :href="checkoutUrl"
            class="WebshopCart__checkoutButton"
          >
            {{ $t("webshop.cart.checkout") }}
          </a>
        </footer>
      </section>
    </FocusTrap>
  </Transition>
</template>
