import {computed, defineAsyncComponent, markRaw, ref} from 'vue'

import Cart from './components/Cart.vue'
import CartButton from './components/CartButton.vue'

const orderScriptTag = document.getElementById('order')
const order = ref(markRaw(orderScriptTag ? JSON.parse(orderScriptTag.innerHTML) : {}))
const hasDiscounts = computed(() => (order.value?.cart ?? []).some(cartItem => cartItem.totalPrice < 0) ?? false)

export default {
  install(app) {
    app.component('webshop-cart', Cart)
    app.component('webshop-cart-button', CartButton)
    app.component('webshop-cart-item', defineAsyncComponent(() => (import('./components/CartItem.vue'))))
    app.component('webshop-hide-if-has-discounts',defineAsyncComponent(() => (import('./components/HideIfOrderHasDiscounts.vue'))))
    app.component('webshop-discount-selector', defineAsyncComponent(() => (import('./components/DiscountSelector.vue'))))
    app.component('webshop-gallery', defineAsyncComponent(() => (import('./components/Gallery.vue'))))
    app.component('webshop-add-to-cart-button', defineAsyncComponent(() => (import('./components/AddToCartButton.vue'))))
    app.component('webshop-product-filters', defineAsyncComponent(() => (import('./components/ProductFilters.vue'))))
    app.component('delivery-information', defineAsyncComponent(() => (import('./components/DeliveryInformation'))))
    app.component('birthday-template-picker', defineAsyncComponent(() => (import('./components/BirthdayTemplatePicker'))))
    app.component('gift-card-selector', defineAsyncComponent(() => (import('./components/GiftCardSelector'))))
    app.component('toggleable-box', defineAsyncComponent(() => (import('./components/ToggleableBox'))))
    app.provide('cart-open', ref(false))
    app.provide('cart-order', order)
    app.provide('cart-has-discounts', hasDiscounts)
  }
}
