{
    "pageHeader": {
        "nav": {
            "close": "Lukk",
            "open": "Meny"
        },
        "closed": "Stengt",
        "openingHoursToday": "Åpent i dag",
        "search": {
            "buttonLabel": "Søk",
            "close": "Lukk",
            "heading": "Søk",
            "inputPlaceholder": "Hva leter du etter?",
            "open": "Søk"
        }
    },
    "ticketing": {
        "loading": "Laster",
        "date": {
            "select": "Velg dato",
            "selected": "Valgt dato",
            "yourSelection": "Du har valgt",
            "redoSelection": "Velg en annen dato"
        },
        "slot": {
            "select": "Velg klokkeslett",
            "selected": "Valgt klokkeslett",
            "selectDifferent": "Velg et annet klokkeslett",
            "full": "Det er desverre ikke flere ledige billetter"
        },
        "product": {
            "minimumSelection": "Velg minst ett produkt",
            "moreInfo": "Mer informasjon",
            "pricePrefix": "Kr",
            "each": "stk.",
            "quantity": {
                "decrease": "Reduser antall",
                "increase": "Øk antall"
            }
        },
        "cart": {
            "add": "Legg til i handlekurv",
            "adding": "Legger til i handlekurv"
        },
        "summary": {
            "total": "Totalt",
            "willBeAddedToCart": "Følgende produkter vil bli lagt til i handlekurven",
            "empty": "Handlekurven er tom",
            "removeItem": "Fjern produkt"
        },
        "change-dates": {
            "pick-date": "Velg Dato",
            "pick-slot": "Velg klokkeslett",
            "pick-tickets": "Velg minst en billett",
            "finish": "Endre dato",
            "full": "Ikke nok plass"
        }
    },
    "webshop": {
        "cart": {
            "add": "Legg til i handlekurv",
            "checkout": "Gå til utsjekk",
            "close": "Lukk",
            "heading": "Handlekurv",
            "loading": "Laster",
            "open": "Åpne handlekurv",
            "removeItem": "Fjern vare",
            "total": "Totalt"
        },
        "quantity": {
            "decrease": "Reduser antall produkter",
            "increase": "Øk antall produkter"
        },
        "discountSelector": {
            "selectDiscountCode": "Velg rabattkode",
            "selectOrEnterDiscountCode": "Velg eller skriv inn rabattkode",
            "enterDiscountCode": "Jeg vil skrive inn egen rabattkode",
            "discountCode": "Fordelskode | Fordelskoder",
            "youSave": "Du sparer {amount} kr",
            "useCode": "Bruk kode",
            "invalidCode": "Ugyldig kode"
        },
        "giftCardSelector": {
            "header": "Skriv inn gavekort nummer"
        },
        "giftCardBuilder": {
            "amount": "Beløp",
            "customAmount": "Valgfritt beløp",
            "amountSuffix": "kr",
            "message": "Melding til mottaker",
            "messageHelpText": "Valgfritt, maks {maxChars} tegn",
            "messageTooManyLines": "Meldingen inneholder for mange linjer",
            "giftCard": "Gavekort",
            "validTo": "Gyldig til",
            "qrCode": "QR-kode",
            "code": "Kode",
            "placeholderTheme": {
                "name": "Laster...",
                "headline": "Gavekort"
            }
        }
    },
    "mer": {
        "levels": {
            "1": "Veldig enkel",
            "2": "Enkel",
            "3": "Normal",
            "4": "Vanskelig",
            "5": "Veldig vanskelig"
        },
        "filters": {
            "heading": "Filter",
            "subject": "Aktivitet",
            "theme": "Tema",
            "level": "Vanskelighetsgrad"
        },
        "noActivities": "Vi kunne ikke finne noen aktiviteter. Prøv å endre på filterene."
    },
    "carousel": {
        "previous": "Forrige",
        "next": "Neste"
    }
}
