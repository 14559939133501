import {inject,} from 'vue'

export default function useOrder() {

  const cartOpen = inject('cart-open')
  const order = inject('cart-order')
  const hasDiscounts = inject('cart-has-discounts')

  const updateOrderAndShowCart = (newOrder, delay = 0) => {
    console.log("updateOrderAndShowCart", newOrder, order)
    order.value = newOrder

    if (delay > 0) {
      setTimeout(() => cartOpen.value = true, delay)
    } else {
      cartOpen.value = true
    }
  }


  const updateGiftcard = async (code) => {
    let response = await axios.post(`/api/orders/${order.value.secret}/gift-cards/${code}`)
    order.value.gift_card = response.data.gift_card
    return true;
  }

  return {
    cartOpen,
    order,
    updateOrderAndShowCart,
    updateGiftcard,
    hasDiscounts
  }
}
